<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <div class="card-title row col">
        <div class="row col">
          <div
            class="
              col-lg-3 col-12
              d-flex
              align-items-center
              position-relative
              my-1
            "
          >
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <inline-svg src="media/icons/duotune/general/gen021.svg" />
            </span>
            <input
              type="text"
              v-model="search"
              @input="tableSearch()"
              class="form-control form-control-solid ps-15"
              placeholder="搜尋"
            />
          </div>
          <div class="col-lg col-12 d-flex align-items-center overflow-auto">
            <el-date-picker
              v-model="value"
              @change="changeDate"
              class="ms-xxl-5 ms-xl-5 ms-0"
              type="datetimerange"
              start-placeholder="開始日期"
              end-placeholder="結束日期"
            >
            </el-date-picker>
          </div>
          <div class="col d-flex justify-content-end">
            <button @click="exportData" type="button" class="btn btn-primary">
              匯出
            </button>
          </div>
        </div>
        <div class="flexBox">
          <div class="me-5">狀態:</div>
          <el-checkbox-group @change="changeCheckList" v-model="statusSelected">
            <template v-for="option in statusOptions" :key="option">
              <el-checkbox :label="option">{{ option }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
        <div class="flexBox">
          <div class="me-5">類別:</div>
          <el-checkbox-group
            @change="changeCheckList"
            v-model="categorySelected"
          >
            <template v-for="option in categoryOptions" :key="option">
              <el-checkbox :label="option">{{ option }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-runningNumber="{ row: tableData }">
          {{ tableData.runningNumber }}
        </template>
        <template v-slot:cell-status="{ row: tableData }">
          {{ tableData.status }}
        </template>
        <template v-slot:cell-category="{ row: tableData }">
          {{ tableData.category }}
        </template>
        <template v-slot:cell-toSimpleUser="{ row: tableData }">
          {{ tableData.toSimpleUser }}
        </template>
        <template v-slot:cell-fromSimpleUser="{ row: tableData }">
          {{ tableData.fromSimpleUser }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
        <template v-slot:cell-description="{ row: tableData }">
          {{ tableData.description }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentItem(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#collectionDetail-modal"
                  >詳細</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 詳細代收資料modal -->
  <div
    class="modal fade"
    id="collectionDetail-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="collectionDetail-exampleModalLabel">
            詳細代收資料
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <CollectionDetail :collection="currentItem"></CollectionDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, toRefs } from "vue";
import { getPackagesByManager } from "@/core/services/api/packages";
import { getSpacesByManager } from "@/core/services/api/spaces";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import CollectionDetail from "./components/CollectionDetail.vue";

export default defineComponent({
  components: {
    Datatable,
    CollectionDetail,
  },
  setup() {
    const allSpacesData: any = reactive([]);
    const currentItem: any = reactive({
      runningNumber: "",
      status: "",
      fromSpaceName: "",
      toSpaceName: "",
      description: "",
      packages: {
        status: "",
        arriveAttach: "",
        pendingAttach: "",
        needTransport: true,
        runningNumber: "",
        fromSpaceName: "",
        toSpaceName: "",
        description: "",
        pendingTime: "",
        from: {
          simpleUser: {
            name: "",
          },
        },
        pickHandler: {
          simpleUser: {
            name: "",
          },
        },
        receiver: {
          simpleUser: {
            name: "",
          },
        },
        pendingHandler: {
          simpleUser: {
            name: "",
          },
        },
        departureCarrier: {
          simpleUser: {
            name: "",
          },
        },
        arriveHandler: {
          simpleUser: {
            name: "",
          },
        },
        immigrationCarrier: {
          simpleUser: {
            name: "",
          },
        },
        transportHandler: {
          simpleUser: {
            name: "",
          },
        },
      },
    });
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const currentSpaceGroups = localstorage.getCurrentSpaces()?.groups;
    const statusSelected: any = ref([]);
    const statusOptions: Array<string> = reactive([]);
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "runningNumber", name: "編號", sortable: true },
      { key: "status", name: "狀態", sortable: true },
      { key: "category", name: "類別", sortable: true },
      { key: "toSimpleUser", name: "收件人", sortable: true },
      { key: "fromSimpleUser", name: "寄件人", sortable: true },
      { key: "createTime", name: "登記時間", sortable: true },
      { key: "description", name: "物品描述", sortable: true },
      { key: "operate", name: "功能" },
    ]);
    const isLoadind = ref(false);
    const packageStatusMap = {
      Pending: "待轉出",
      TransportOut: "已轉出",
      Transporting: "待轉入",
      Arrival: "待領取",
      Picked: "已領取",
      Rejected: "已退件",
    };
    const categoryOptions = [
      "文件",
      "限時掛號",
      "包裹",
      "國際文件",
      "快捷",
      "國際包裹",
      "物品",
      "普通掛號",
      "雙掛號",
      "其他",
    ];
    const categorySelected = ref([
      "文件",
      "限時掛號",
      "包裹",
      "國際文件",
      "快捷",
      "國際包裹",
      "物品",
      "普通掛號",
      "雙掛號",
      "其他",
    ]);

    const datePicker = reactive({
      value: [
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });

    const changeDate = async () => {
      await setTableData();
    };

    // 過濾狀態
    const filterSataus = (data) => {
      return data.filter((o) => statusSelected.value.includes(o.status));
    };

    // 過濾類別
    const filterCategory = (data) => {
      return data.filter((o) => categorySelected.value.includes(o.category));
    };

    // 過濾掉其他空間代轉出包裹
    const filterOtherSpacePendingPackages = (o) => {
      return o.filter(
        (p) => !(p.status === "Pending" && p.toSpace === currentSpaceGuid)
      );
    };

    // 取得空間名稱
    const gerSpaceName = (guid) => {
      return allSpacesData.filter((s) => s.guid == guid)[0].name;
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let request = {
        space: currentSpaceGuid,
        beginTime: getUtcTimeString(datePicker.value[0]),
        endTime: getUtcTimeString(datePicker.value[1]),
        includeUnEnd: false,
      };
      let packages: any = await getPackagesByManager(request);
      packages = filterOtherSpacePendingPackages(packages);
      tableData.splice(0);
      packages.forEach((o) => {
        tableData.push({
          packages: o,
          runningNumber: o.runningNumber,
          status: packageStatusMap[judgmentStatus(o)],
          category: o.category,
          toSimpleUser: o.to.simpleUser.name,
          fromSimpleUser: o.from.simpleUser.name,
          createTime: getLocalTimeString(o.createTime),
          fromSpaceName: gerSpaceName(o.fromSpace),
          toSpaceName: gerSpaceName(o.toSpace),
          needTransport: o.fromSpace !== o.toSpace,
          description: o.description,
        });
      });

      tableData.splice(0, tableData.length, ...filterSataus(tableData));
      tableData.splice(0, tableData.length, ...filterCategory(tableData));
      initTableData.splice(0, initTableData.legth, ...tableData);
      isLoadind.value = false;
      console.log("tableData", tableData);
    };

    // 狀態判斷
    const judgmentStatus = (packageData) => {
      let status = packageData.status;
      let toSpace = packageData.toSpace;
      switch (status) {
        case "Transporting":
          return toSpace === currentSpaceGuid ? "Transporting" : "TransportOut";
        case "Arrival":
          return toSpace === currentSpaceGuid ? "Arrival" : "TransportOut";
        case "Picked":
          return toSpace === currentSpaceGuid ? "Picked" : "TransportOut";
        default:
          return status;
      }
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    // 設置狀態選項
    const setStatusOptions = () => {
      let isGroups = currentSpaceGroups!.length > 0;
      if (isGroups) {
        statusOptions.splice(
          0,
          statusOptions.length,
          ...["待轉出", "已轉出", "待轉入", "待領取", "已領取", "已退件"]
        );
        statusSelected.value = JSON.parse(JSON.stringify(statusOptions));
      } else {
        statusOptions.splice(
          0,
          statusOptions.length,
          ...["待領取", "已領取", "已退件"]
        );
        statusSelected.value = JSON.parse(JSON.stringify(statusOptions));
      }
    };

    const changeCheckList = async () => {
      await setTableData();
    };

    const getTimeString = (time) => {
      if (time == "0001-01-01T00:00:00.000Z") {
        return "";
      } else {
        return getLocalTimeString(time);
      }
    };

    // 設置所有空間資料
    const setAllSpacesData = async () => {
      let allSpaces = await getSpacesByManager();
      let spacesData: any = [];
      allSpaces.forEach((s) => {
        spacesData.push({
          name: s.space.name,
          guid: s.space.guid,
        });
      });
      allSpacesData.splice(0, allSpacesData.length, ...spacesData);
    };

    // 匯出資料
    const exportData = () => {
      Swal.fire({
        title: "確定要匯出資料嗎?",
        text: "資料將根據您過濾的時間、狀態、類別匯出",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定!",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.isConfirmed) {
          //要匯出的json資料
          var jsonData: any = [];
          tableData.forEach((o) => {
            jsonData.push({
              runningNumber: o.runningNumber,
              status: o.status,
              category: o.category,
              fromSpaceName: gerSpaceName(o.packages.fromSpace),
              toSpaceName: gerSpaceName(o.packages.toSpace),
              description: o.description,
              isTransport: o.needTransport ? "是" : "否",

              // 包裹登記
              // 登記時間
              checkinTime: getTimeString(o.packages.createTime),
              // 登記處理人
              checkinHandler: o.needTransport
                ? o.packages.pendingHandler.simpleUser.name
                : o.packages.ArrivalHandler.simpleUser.name,
              // 物品來源
              source: o.packages.from.simpleUser.name,

              // 轉出登記
              // 轉出時間
              outTime: getTimeString(o.packages.transportTime),
              // 轉出處理人
              outHandler: o.packages.transportHandler.simpleUser.name,
              // 轉出人
              outName: o.packages.departureCarrier.simpleUser.name,

              // 轉入登記
              // 轉入時間
              inTime: getTimeString(o.packages.arriveTime),
              // 轉入處理人
              inHandler: o.packages.arriveHandler.simpleUser.name,
              // 轉入人
              inName: o.packages.immigrationCarrier.simpleUser.name,

              // 包裹領取
              // 領取時間
              receiveTime: getTimeString(o.packages.pickTime),
              // 領取處理人
              receiveHandler: o.packages.pickHandler.simpleUser.name,
              // 領取人
              receiveName: o.packages.receiver.simpleUser.name,
            });
          });

          //列標題，逗號隔開，每一個逗號就是隔開一個單元格
          let str = `編號,物品狀態,物品類別,物品來源地,物品目的地,備註事項,是否轉運,登記時間,登記處理人,物品來源,轉出時間,轉出處理人,轉出人,轉入時間,轉入處理人,轉入人,領取時間,領取處理人,領取人\n`;
          //增加\t為了不讓表格顯示科學計數法或者其他格式
          for (let i = 0; i < jsonData.length; i++) {
            for (let item in jsonData[i]) {
              str += `${jsonData[i][item] + "\t"},`;
            }
            str += "\n";
          }
          //encodeURIComponent解決中文亂碼
          let uri =
            "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
          //通過建立a標籤實現
          var link = document.createElement("a");
          link.href = uri;
          let filterBeginTime = moment(datePicker.value[0]).format(
            "YYYY-MM-DD"
          );
          let filterEndTime = moment(datePicker.value[1]).format("YYYY-MM-DD");
          //對下載的檔案命名
          link.download = `物品代收紀錄(${filterBeginTime}_${filterEndTime}).csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const init = async () => {
      await setAllSpacesData();
      setStatusOptions();
      await setTableData();
    };
    init();

    return {
      ...toRefs(datePicker),
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      changeDate,
      statusSelected,
      changeCheckList,
      statusOptions,
      setStatusOptions,
      categoryOptions,
      categorySelected,
      exportData,
      setCurrentItem,
      currentItem,
    };
  },
});
</script>

<style scoped>
.flexBox {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
</style>