<template>
  <div>
    <b-modal id="packageDetail-modal" size="xl" title="物品詳細資訊" ok-only>
      <div class="row">
        <div class="col-4">
          <div class="row my-5">
            <div class="col-4 detailTitle">編號:</div>
            <div class="col">{{ props.collection.runningNumber }}</div>
          </div>
          <div class="row my-5">
            <div class="col-4 detailTitle">物品狀態:</div>
            <div class="col">{{ props.collection.status }}</div>
          </div>
          <div class="row my-5">
            <div class="col-4 detailTitle">物品來源地:</div>
            <div class="col">{{ props.collection.fromSpaceName }}</div>
          </div>
          <div class="row my-5">
            <div class="col-4 detailTitle">物品目的地:</div>
            <div class="col">{{ props.collection.toSpaceName }}</div>
          </div>
          <div class="row my-5">
            <div class="col-4 detailTitle">備註事項:</div>
            <div class="col">{{ props.collection.description }}</div>
          </div>
        </div>
        <div class="col">
          <div style="font-size: 17px; font-weight: bold">物品詳細流程:</div>
          <el-collapse
            class="accordion"
            v-model="activeNames"
            @change="handleChange"
          >
            <!-- 包裹登記 -->
            <el-collapse-item title="包裹登記" name="1">
              <div class="row">
                <div class="col-6">
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">登記時間:</div>
                    <div class="col">
                      {{ getFormatTime(props.collection.createTime) }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">登記處理人:</div>
                    <div v-if="props.collection.needTransport" class="col">
                      {{
                        props.collection.packages.pendingHandler.simpleUser.name
                      }}
                    </div>
                    <div v-else class="col">
                      {{
                        props.collection.packages.arriveHandler.simpleUser.name
                      }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">物品來源:</div>
                    <div class="col">
                      {{ props.collection.packages.from.simpleUser.name }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div style="font-size: 16px; font-weight: bold">
                    包裹影像:
                  </div>
                  <img
                    v-if="props.collection.needTransport"
                    class="img-fluid"
                    :src="getFile(props.collection.packages.pendingAttach)"
                  />
                  <img
                    v-else
                    class="img-fluid"
                    :src="getFile(props.collection.packages.arriveAttach)"
                  />
                </div>
              </div>
            </el-collapse-item>
            <!-- 轉出登記 -->
            <el-collapse-item
              v-if="props.collection.needTransport && activeStep > 1"
              title="轉出登記"
              name="2"
            >
              <div class="row">
                <div class="col-6">
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">轉出時間:</div>
                    <div class="col">
                      {{
                        getFormatTime(props.collection.packages.transportTime)
                      }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">轉出處理人:</div>
                    <div class="col">
                      {{
                        props.collection.packages.transportHandler.simpleUser
                          .name
                      }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">轉出人:</div>
                    <div class="col">
                      {{
                        props.collection.packages.departureCarrier.simpleUser
                          .name
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div style="font-size: 16px; font-weight: bold">
                    轉出人簽名:
                  </div>
                  <img
                    class="img-fluid"
                    :src="getFile(props.collection.packages.transportAttach)"
                  />
                </div>
              </div>
            </el-collapse-item>
            <!-- 轉入登記 -->
            <el-collapse-item
              v-if="props.collection.needTransport && activeStep > 2"
              title="轉入登記"
              name="3"
            >
              <div class="row">
                <div class="col-6">
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">轉入時間:</div>
                    <div class="col">
                      {{ getFormatTime(props.collection.packages.arriveTime) }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">轉入處理人:</div>
                    <div class="col">
                      {{
                        props.collection.packages.arriveHandler.simpleUser.name
                      }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">轉入人:</div>
                    <div class="col">
                      {{
                        props.collection.packages.immigrationCarrier.simpleUser
                          .name
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div style="font-size: 16px; font-weight: bold">
                    轉出人簽名:
                  </div>
                  <img
                    class="img-fluid"
                    :src="getFile(props.collection.packages.arriveAttach)"
                  />
                </div>
              </div>
            </el-collapse-item>
            <!-- 包裹領取 -->
            <el-collapse-item v-if="activeStep >= 3" title="包裹領取" name="4">
              <div class="row">
                <div class="col-6">
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">領取時間:</div>
                    <div class="col">
                      {{ getFormatTime(props.collection.packages.pickTime) }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">領取處理人:</div>
                    <div class="col">
                      {{
                        props.collection.packages.pickHandler.simpleUser.name
                      }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 circuitTitle">領取人:</div>
                    <div class="col">
                      {{ props.collection.packages.receiver.simpleUser.name }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div style="font-size: 16px; font-weight: bold">
                    領取人簽名:
                  </div>
                  <img
                    class="img-fluid"
                    :src="getFile(props.collection.packages.pickAttach)"
                  />
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref, watch } from "vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  props: ["collection"],
  setup(props) {
    const activeStep = ref(0);
    const transportStepDictionary = {
      Pending: 0,
      Transporting: 1,
      Arrival: 2,
      Picked: 3,
      Rejected: 4,
    };
    watch(
      props,
      () => {
        activeStep.value =
          transportStepDictionary[props.collection.packages.status];
      },
      { immediate: true, deep: true }
    );
    const activeNames = ref([]);

    const handleChange = (val) => {
      console.log(val);
    };

    const getFormatTime = (time) => {
      if (time == "0001-01-01T00:00:00.000Z") {
        return "";
      } else {
        return getLocalTimeString(time);
      }
    };

    const getFile = (file) => {
      if (file == undefined || file == "") {
        return "";
      } else {
        return getFilePath(file);
      }
    };

    return {
      props,
      activeNames,
      handleChange,
      getFormatTime,
      getFile,
      activeStep,
    };
  },
});
</script>

<style scoped>
.detailTitle {
  font-size: 16px;
  font-weight: bold;
}
.circuitTitle {
  font-weight: bold;
}
.accordion img {
  max-width: 250px;
  max-height: 250px;
}
</style>