import ApiService from "@/core/services/ApiService";
import { PackageExtend } from "@/core/services/responsesModel/packageExtend"

// 管理者查詢包裹
export function getPackagesByManager(request):Promise<PackageExtend> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Packages/QueryByManager`, request).then(res => {
            resolve(res.data.packages)
        }).catch(err => {
            reject(err)
        })
    })
}