
import { defineComponent, ref, watch } from "vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  props: ["collection"],
  setup(props) {
    const activeStep = ref(0);
    const transportStepDictionary = {
      Pending: 0,
      Transporting: 1,
      Arrival: 2,
      Picked: 3,
      Rejected: 4,
    };
    watch(
      props,
      () => {
        activeStep.value =
          transportStepDictionary[props.collection.packages.status];
      },
      { immediate: true, deep: true }
    );
    const activeNames = ref([]);

    const handleChange = (val) => {
      console.log(val);
    };

    const getFormatTime = (time) => {
      if (time == "0001-01-01T00:00:00.000Z") {
        return "";
      } else {
        return getLocalTimeString(time);
      }
    };

    const getFile = (file) => {
      if (file == undefined || file == "") {
        return "";
      } else {
        return getFilePath(file);
      }
    };

    return {
      props,
      activeNames,
      handleChange,
      getFormatTime,
      getFile,
      activeStep,
    };
  },
});
